import React, {useState, useMemo, useContext} from "react"

const AppContext = React.createContext({})

const useAppState = () => {
    const initialState = {
        qList: [],
        aList: [],
        share: false
    }
    const [state, setState] = useState(initialState)
    const actions = useMemo(() => getActions(setState), [setState])

    return { state, actions }
}

const getActions = (setState) => ({
    setList: (newValue) => {
        setState(state => {
            return ({...state, qList: newValue})
        })
    },
    setAnswerList: (newValue) => {
        setState(state => {
            return ({...state, aList: newValue})
        })
    },
    setShare: (newValue) => {
        setState(state => {
            return ({...state, share: newValue})
        })
    },
})

const useAppContext = () => {
    return useContext(AppContext)
}

export { AppContext, useAppState, useAppContext };