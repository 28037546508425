// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-ergebnis-js": () => import("./../../../src/pages/ergebnis.js" /* webpackChunkName: "component---src-pages-ergebnis-js" */),
  "component---src-pages-frage-1-js": () => import("./../../../src/pages/frage1.js" /* webpackChunkName: "component---src-pages-frage-1-js" */),
  "component---src-pages-frage-10-js": () => import("./../../../src/pages/frage10.js" /* webpackChunkName: "component---src-pages-frage-10-js" */),
  "component---src-pages-frage-2-js": () => import("./../../../src/pages/frage2.js" /* webpackChunkName: "component---src-pages-frage-2-js" */),
  "component---src-pages-frage-3-js": () => import("./../../../src/pages/frage3.js" /* webpackChunkName: "component---src-pages-frage-3-js" */),
  "component---src-pages-frage-4-js": () => import("./../../../src/pages/frage4.js" /* webpackChunkName: "component---src-pages-frage-4-js" */),
  "component---src-pages-frage-5-js": () => import("./../../../src/pages/frage5.js" /* webpackChunkName: "component---src-pages-frage-5-js" */),
  "component---src-pages-frage-6-js": () => import("./../../../src/pages/frage6.js" /* webpackChunkName: "component---src-pages-frage-6-js" */),
  "component---src-pages-frage-7-js": () => import("./../../../src/pages/frage7.js" /* webpackChunkName: "component---src-pages-frage-7-js" */),
  "component---src-pages-frage-8-js": () => import("./../../../src/pages/frage8.js" /* webpackChunkName: "component---src-pages-frage-8-js" */),
  "component---src-pages-frage-9-js": () => import("./../../../src/pages/frage9.js" /* webpackChunkName: "component---src-pages-frage-9-js" */),
  "component---src-pages-fragen-js": () => import("./../../../src/pages/fragen.js" /* webpackChunkName: "component---src-pages-fragen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nutzungsbedingungen-js": () => import("./../../../src/pages/nutzungsbedingungen.js" /* webpackChunkName: "component---src-pages-nutzungsbedingungen-js" */)
}

