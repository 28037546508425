import React from "react"
import { useAppState } from "../hooks/useAppState"

const initialState = {
    state: {
        progress: 0,
        true: 0  
    }
}

export const StateContext = React.createContext(initialState)

export const StateProvider = ({ children }) => {
    const { state, actions } = useAppState()

    return (
        <StateContext.Provider value={{ state, actions }}>
            {children}
        </StateContext.Provider>
    );
}